.app {
  background-color: #282c34;

  &-header {
    align-items: center;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;

    &-wrapper {
      text-align: center;

      &-poster {
        padding-top: 16px;
        width: 80%;
      }
    }
  }

  &-footer {
    color: white;
    padding: 16px 0;
    text-align: center;

    a {
      color: inherit;
    }
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
